export type SiteConfig = typeof siteConfig

export const siteConfig = {
  siteName: "CraftStadium",
  name: "CraftStadium | 熱狂的なものづくりのハッカソンスタジアム",
  description:
    "CraftStadium（クラスタ）は、熱狂と革新的な評価制度のハッカソン、「レイド」が開催されるスタジアム。実績が証明されるバッジや、ギルドでの切磋琢磨、新しい評価制度による真のプロダクトづくりを意識したエンジニアリングへ。エンジニアリングは大衆も熱狂するsportsだ。",
  absolutePath:
    "https://s3-copalette-dev.s3.ap-northeast-1.amazonaws.com/uGRsdUvteTH1L7b5wx3s5AzK9SaLQlwegYzQ4VJzTXt6hAztjEIHhoV44WZW48bN9LIqIHdlLPL73dfmfVrPPYA7FK4jsdPxGTJH",
  navItems: [
    {
      title: "概要",
      items: [
        {
          label: "サービス概要",
          href: "/overview",
        },
        {
          label: "スポンサー",
          href: "/sponsor",
        },
      ],
    },
    {
      title: "ヘルプ",
      items: [
        {
          label: "用語集",
          href: "/help/glossary",
        },
        {
          label: "レイド共通ルール",
          href: "/help/common-rules",
        },
        {
          label: "罰則点ルール",
          href: "/help/penalty-rules",
        },
        {
          label: "プレイヤーポリシー",
          href: "/help/player-policy",
        },
        {
          label: "利用規約",
          href: "/help/terms",
        },
        {
          label: "プライバシーポリシー",
          href: "/help/privacy",
        },
      ],
    },
    {
      title: "ブログ",
      items: [
        {
          label: "ブログ一覧",
          href: "/blog",
        },
        {
          label: "攻略法",
          href: "/blog/tag/complete-guide",
        },
        {
          label: "ハイライト",
          href: "/blog/tag/hilight",
        },
        {
          label: "サービス情報",
          href: "/blog/tag/stadium-info",
        },
        {
          label: "ハッカソン",
          href: "/blog/tag/hackathon",
        },
      ],
    },
    {
      title: "リザルト",
      items: [
        {
          label: "その場で盛り上がるプロダクトを作ろう",
          href: "/result/RR240629",
        },
      ],
    },
  ],
  footerItems: [
    {
      title: "概要",
      items: [
        {
          label: "サービス概要",
          href: "/overview",
        },
        {
          label: "スポンサー",
          href: "/sponsor",
        },
      ],
    },
    {
      title: "ハッカソン",
      items: [
        {
          label: "ハッカソンリスト",
          href: "/",
        },
        {
          label: "リザルト",
          href: "/result/RR240629",
        },
      ],
    },
    {
      title: "リソース",
      items: [
        {
          label: "ブログ",
          href: "/blog",
        },
        {
          label: "ヘルプ",
          href: "/help",
        },
        {
          label: "美術館",
          href: "/museum",
        },
      ],
    },
    {
      title: "会社",
      items: [
        {
          label: "会社概要",
          href: "https://www.copalette.org/",
        },
        {
          label: "コンタクト",
          href: "https://www.copalette.org/contact",
        },
      ],
    },
  ],
  links: {
    x: "https://twitter.com/CraftStadium",
    discord: "https://discord.gg/kQvdQEa4uD",
    sponsor: "https://forms.gle/TTsfjLrxg658Q9u69",
  },
}
